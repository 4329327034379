import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  Country,
  LoginRequestData,
  RegisterRequestData,
} from "../models/interface.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  endpoint: string = environment.endpoint;
  constructor(private http: HttpClient) {}

  getCountryList() {
    return this.http.get<Country[]>("/assets/country.json");
  }

  login(request: LoginRequestData) {
    return this.http.post(this.endpoint + "/login", request);
  }

  login_2fa(request: { code: string }) {
    return this.http.post(this.endpoint + "/verify_2fa_auth", request);
  }

  register(request: RegisterRequestData) {
    return this.http.post(this.endpoint + "/registration", request);
  }

  emailVerification(request: { email: string; token: string }) {
    return this.http.post(this.endpoint + "/verify_email", request);
  }

  requestPasswordResetToken(request: { email: string }) {
    return this.http.post(this.endpoint + "/password_reset_request", request);
  }

  resetPassword(request: { email: string; token: string; password: string }) {
    return this.http.post(this.endpoint + "/reset_password", request);
  }

  requestNewEmailToken(request: { email: string }) {
    return this.http.post(this.endpoint + "/request_new_email_token", request);
  }
}
