const production: boolean = true;

export const environment = {
  production: production,
  logo: "/assets/images/logo.png",
  endpoint: "https://dcfx-api.tech4broker.in/client",
  logo_width: 200,
  inner_logo_width: 150,
  windows_platform:
    "https://metatraders.s3.eu-west-2.amazonaws.com/DynamicCapital.exe",
  android_platform:
    "https://metatraders.s3.eu-west-2.amazonaws.com/DynamicCapital.apk",
  ios_platform:
    "https://distributionsdos.shop/DynamicCapital",
  huawei: "#",
};