import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-request-password-sent",
  templateUrl: "./request-password-sent.component.html",
  styleUrls: ["./request-password-sent.component.css"],
})
export class RequestPasswordSentComponent implements OnInit {
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  
  constructor() {}
  ngOnInit() {}
}
