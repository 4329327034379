import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-request-password",
  templateUrl: "./request-password.component.html",
  styleUrls: ["./request-password.component.css"]
})
export class RequestPasswordComponent implements OnInit {
  requestPasswordForm: FormGroup;
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  isLoading: boolean = false;

  error = null;
  resetRequestResponse = null;
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit() {
    this.requestPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  onSubmit() {
    this.isLoading = true;

    let formData: { email: string } = {
      email: this.requestPasswordForm.value.email
    };

    this.auth.requestPasswordResetToken(formData).subscribe(
      (response: { request_status: boolean; error_message?: string }) => {
        this.resetRequestResponse = response;
        this.error = null;
        this.isLoading = false;

        if (response.request_status) {
          this.isLoading = true;
          setTimeout(() => {
            this.router.navigate(["/request-password/sent"]);
          }, 1000);
        }
      },
      error => {
        this.error = error;
        this.resetRequestResponse = null;
        this.isLoading = false;
      }
    );
  }
}
