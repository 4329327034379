import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../service/auth.service";

@Component({
  selector: "app-request-verifivation-token",
  templateUrl: "./request-verifivation-token.component.html",
  styleUrls: ["./request-verifivation-token.component.css"],
})
export class RequestVerifivationTokenComponent implements OnInit {
  logo: string = environment.logo;
  logo_width: number = environment.logo_width;
  isLoading: boolean = false;
  requestTokenForm: FormGroup;
  error = null;
  newTokenResponse = null;

  email: string = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.email = this.route.snapshot.queryParams["email"];

    this.requestTokenForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    });

    this.setFormDefault();
  }

  setFormDefault() {
    if (this.email) {
      this.requestTokenForm.setValue({
        email: this.email,
      });
    }
  }

  onSubmit() {
    this.isLoading = true;

    let formData: { email: string } = {
      email: this.requestTokenForm.value.email,
    };

    this.auth.requestNewEmailToken(formData).subscribe(
      (response: { request_status: boolean; error_message: string }) => {
        this.newTokenResponse = response;
        this.error = null;
        this.isLoading = false;

        if (response.request_status) {
          this.isLoading = true;
          setTimeout(() => {
            this.router.navigate(["/login"]);
          }, 3000);
        }
      },
      (error) => {
        this.error = error;
        this.newTokenResponse = null;
        this.isLoading = false;
      }
    );
  }
}
